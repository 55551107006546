<template>
  <div
    v-if="isShowPremiumPaidMember"
    v-expose="{
      id: '1-11-1-78',
      data: primeOptionAnalysis,
    }"
  >
    <component
      :is="clubProductPackageCom"
      v-if="isNewPrimeVersion" 
      :selected-prime="selectedPrime"
      :not-support="notSupport"
      :now-payment="nowPayment"
      :prime-deduce-price="primeDeducePrice"
      :paypal-ga-vault="paypalGaVault"
      :banks-config="banksConfig"
      :payment-methods-props="paymentMethodsProps"
      :data-club-ver="clubProductPackageCom"
    />
    <PrimeOptions 
      v-else 
      :selected-prime="selectedPrime"
      :not-support="notSupport"
      :now-payment="nowPayment"
      :prime-deduce-price="primeDeducePrice"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import PrimeOptions from './primeOptions.vue'
import ClubProductPackageAdapter from '../product-package/club/ClubProductPackageAdapter.vue'
import PrimeOptionsNew from './prime_options_new.vue'

export default {
  name: 'PrimeOptionsWrap',
  components: {
    PrimeOptions,
    PrimeOptionsNew,
    ClubProductPackageAdapter
  },
  props: {
    paypalGaVault: {
      type: Object,
      default: () => {}
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'isNewPrimeVersion',
    ]),
    ...mapState([
      'checkout',
      'status',
      'primeProductList', 
      'usedPrimeIndex', 
      'selectdPrimeIndex',
      'rightConfigList',
    ]),
    primeOptionAnalysis() {
      if(typeof window == 'undefined') return {}
      
      return {
        select_flag: '',
        privilege_count:
          this.rightConfigList.length > 1 ? this.rightConfigList.length : 0,
        buy_num_accumulate:
          this.checkout.results?.primeProductInfo?.buyEffectiveNum,
      }
    },
    isShowPremiumPaidMember() {
      if (!this.primeProductList?.length) {
        return false
      }
      if (this.checkout?.checkoutBFFAbtInfo?.NewUserClub?.param?.NewUserClub == 'new') {
        // 在一单回本的情况下，展示付费会员产品包
        const singleReturn = this.checkout.results?.primeProductInfo?.primeProductList?.some(item => {
          return +item?.product_price_info?.special_price <= +this.checkout?.mall_caculate_info?.prime_trial_total_discount_price?.amount
        })
        return singleReturn || this.usedPrimeIndex != -1
      }
      return true
    },
    defaultPrime() {
      // 未勾选时，默认展示is_recommend(推荐产品包),若无展示第一个 
      const recommendProduct = this.primeProductList?.filter(item => +item.is_recommend) || []
      return recommendProduct.length ? recommendProduct[0] : this.primeProductList?.[0] || {}
    },
    selectedPrime() {
      if (this.usedPrimeIndex != -1) { // 已勾选
        return this.primeProductList?.[this.usedPrimeIndex] || {}
      } else { // 未勾选
        if (this.selectdPrimeIndex == null || this.selectdPrimeIndex == -1) {
          // 未勾选展示默认产品包
          return this.defaultPrime
        } else { // 取消当前选择
          return this.primeProductList?.[this.selectdPrimeIndex] || {}
        }
      }
    },
    paymentMethods() {
      return this.checkout.results?.paymentMethods || []
    },
    nowPayment() {
      if(!this.status?.cardPay) return {}
      return this.paymentMethods?.filter(item => item.code == this.status.cardPay)?.[0] || {}
    },
    notSupport() {
      return this.nowPayment.support_membership_with_order == 0 || this.nowPayment.support_xtra_with_order == 0
    },
    primeDeducePrice() {
      return this.checkout?.mall_caculate_info?.prime_total_price || {}
    },
    // club 组件
    clubProductPackageCom(){
      const abtContral = this.checkout?.checkoutBFFAbtInfo?.comfororder?.p?.comforclub == 1
      return abtContral ? 'ClubProductPackageAdapter' : 'PrimeOptionsNew'
    }
  },
}
</script>
