<template>
  <!-- 部分quickShip，不需要qs运费 -->
  <div
    v-if="quickShipFreeShow"
    class="bag-quick-time"
    @click.stop="showQuickPop"
  >
    <div class="qsLabel-container">
      <quickShipLabel 
        :style-config="{
          'font-size': '12px',
          'font-weight': '700',
        }"
        :show-line="true"
      />
    </div>
    <span
      class="qsLabel-text"
      v-html="descQuickShipTime"
    ></span>
  </div> 
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import quickShipLabel from 'public/src/pages/checkout/components/atom/quickShipLabel.vue'

export default {
  name: 'QuickShipFreeTips',
  components: {
    quickShipLabel,
  },
  props: {
    cartsData: {
      type: Array,
      default: () => []
    },
    shippingMethod: {
      type: Object,
      default: () => {}
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters(['iconPrefix', 'quickShipCheckoutStatus']),
    ...mapState(['checkout']),
    quickShipFreeShow(){
      const isAllCartsQuickShip = this.cartsData?.every((item) => +item?.quick_ship && item?.real_quick_ship == 1)

      const qsMakeup = this.checkout?.checkoutBFFAbtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
      const qs_freight_status = this.shippingMethod?.qs_freight_status
      const showQsFreight = qs_freight_status == 1 && qsMakeup != 0

      const { quickShipStyle } = this.quickShipCheckoutStatus || {}
      return !isAllCartsQuickShip && !showQsFreight && this.descQuickShipTime && quickShipStyle
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    showQuickPop() {
      this.assignState({
        isShowQuickPop: true
      })
    },
  }
}
</script>
<style lang="less" scoped>
.bag-quick-time{
    display: flex;
    padding: 10px 12px;
    height: 50px;
    align-items: center;
    align-self: stretch;
    background: var(---sui_color_safety_bg, #F5FCFB);
    border: 1px solid #BADECD;
    border-radius: 3px;
    margin-bottom: 8px;
    cursor: pointer;
    .qsLabel-container{
      display: flex;
      align-items: center;
      margin-right: 5px;
      max-width: 40%;
    }
    // 不用border-right，高度不好匹配UI稿
    .qsLabel-line {
      width: 1px;
      height: 14px;
      background: #8DC2AB;
      margin-left: 6px;
    }
    .qsLabel-text{
      margin-left: 3px;
      overflow: hidden;  
      text-overflow: ellipsis; 
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
}
</style>
