<template>
  <div
    v-expose="{
      id: '1-11-1-157',
      data: xtraOptionAnalysis
    }"
    class="checkout-xtra__wrap not-fsp-element"
  >
    <img
      class="checkout-xtra__wrap-logo not-fsp-element"
      :src="xtraLogoImg"
      alt=""
    />

    <div
      class="checkout-xtra__wrap-body"
      @click="clickXtra({type: 'all'})"
    >
      <s-radio 
        v-if="!saverNewStyleAbt"
        v-model="xtraSeleted"
        :toggle="true"
        :disabled="notSupport || status.hasAddress == 0"
        :label="true"
        size="radio20"
        theme="icon"
        gap="0"
        class="checkout-xtra__wrap-radio" 
        style="align-items: flex-start;"
      />
      <div
        class="checkout-xtra__wrap-content"
      >
        <template 
          v-if="!saverNewStyleAbt"
        >
          <div
            class="checkout-xtra__wrap-name"
            :class="{'disabled': notSupport || status.hasAddress == 0}"
          >
            <span 
              class="checkout-xtra__wrap-price-special" 
              :class="{
                'discount-price': isShowSpecialPriceHighColor,
                'checkout-xtra__price_ar': GB_cssRight
              }"
            >{{ newProductPriceInfo.special_price_with_symbol }}</span>
            <template v-if="showDiscount">
              <del 
                v-if="!hideSaverStrikethroughPrice"
                class="checkout-xtra__wrap-price-del" 
              >{{ newProductPriceInfo.price_local_with_symbol }}</del>
              <!-- 会费券引导文案不隐藏 -->
              <span 
                v-if="!hideSaverStrikethroughPrice || sceneLogic[productCode].isTips"
                :class="['checkout-xtra__wrap-price-discount', {'checkoutXtra__activeXtra-dicount_bg':sceneLogic[productCode].isTips}]"
                @click="clickDiscountTips($event)"
              >
                {{ discountTips }}
              </span>
            </template>
          </div>
          <p
            class="checkout-xtra__wrap-des"
            :class="{'disabled': notSupport || status.hasAddress == 0}"
            v-html="roiTips"
          >
          </p>
        </template>

        <!-- 新样式 -->
        <template v-else>
          <p
            class="checkout-xtra__NewWrapDesTitle"
            :class="{'disabled': notSupport || status.hasAddress == 0}"
            v-html="xtraPriceTips"
          >
          </p>
          <p
            v-if="!saverHeightByAbt"
            class="checkout-xtra__NewWrapDes"
            :class="{'disabled': notSupport || status.hasAddress == 0}"
            v-html="roiTips"
          >
          </p>
        </template>


        <XtraCouponList
          v-if="saverNewStyleAbt"
          class="checkout-xtra__wrap-list" 
          @click-details="clickDetails"
        />
        <CouponWrap
          v-else 
          class="checkout-xtra__wrap-coupon"
          :class="{'disabled': notSupport || status.hasAddress == 0}"
          :item="xtraDisplayCouponInfo.info"
          :type="xtraDisplayCouponInfo.type"
          :total="xtraDisplayCouponInfo.count"
          @click.stop="clickDetails"
        />

        <XtraRadioSelected
          v-if="saverNewStyleAbt"
          ref="xtraRadioSelectedRef"
          :xtra-seleted="xtraSeleted"
          :not-support="notSupport"
          :is-has-auto-renewed-bag="isHasAutoRenewedBag"
          :payment-methods-props="paymentMethodsProps"
          :bubble-down="bubbleDown"
          :auto-payment-visible="autoPaymentVisible"
          @click-discount-tips="xtraRadioSelectClickDisountCb"
          @click-radio="clickXtra"
          @bubble-close="(val)=> { bubbleDown = true }"
        />

        <p
          class="checkout-xtra__wrap-policy"
          :class="{'disabled': notSupport || status.hasAddress == 0}"
        >
          <span 
            @click.stop="clickPolicy" 
            v-html="policyTips"
          >
          </span>
          |
          <span
            v-if="isShowAutoRenewedProtocol"
            class="auto-renewed-protocol"
            @click.stop="autoRenewalDialogVisible = true"
          >{{ language.SHEIN_KEY_PC_30954 }}</span>
          <template v-else>
            <span 
              class="polict-detail" 
              @click.stop="clickDetails"
            >{{ language.SHEIN_KEY_PC_26929 }}</span>
          </template>
        </p>
        <p
          v-if="notSupport && status.cardPay"
          class="checkout-xtra__wrap-disabled not-fsp-element"
        >
          <sui_icon_reported_14px_1
            size="12px"
            color="#bf4123"
          />
          {{ template(nowPayment.title, language.SHEIN_KEY_PC_27083) }}
        </p>
      </div>
    </div>

    <ClientOnly>
      <div v-if="isClient">
        <XtraCoupon 
          :total="xtraDisplayCouponInfo.count"
          :new-product-price-info="newProductPriceInfo"
          :apply-coupons-vir="applyCouponsVir"
          :payment-methods-props="paymentMethodsProps"
          :is-has-auto-renewed-bag="isHasAutoRenewedBag"
          :disabled="notSupport || status.hasAddress == 0"
          :auto-payment-visible="autoPaymentVisible"
          @choose-xtra="chooseXtra"
        />

        <autoRenewedDialog
          :visible="autoRenewalDialogVisible"
          @close-dialog="autoRenewalDialogVisible = false"
        />

        <s-dialog
          v-model:visible="discountTipsVisible"
          :type="'W480'"
          show-close
        >
          <p>{{ language.SHEIN_KEY_PC_27391 }}</p>
          <template #footer>
            <s-button
              :type="['primary', 'H44PX']"
              @click="discountTipsVisible=false"
            >
              {{ language.SHEIN_KEY_PC_15216 }}
            </s-button>
          </template>
        </s-dialog>

        <s-dialog
          v-model:visible="virXrtCouponState.virtunalDiscountVisible"
          show-close
        >
          <template #icon>
            <img
              :src="imgAll.dialogHeaderBg"
              class="header-bg"
            />
          </template>
          <template #title> 
            <div class="header-bg-container">
              {{ language.SHEIN_KEY_PC_28828 }} 
              <div class="pickColor_xtra xtra-text">
                {{ newProductPriceInfo.reduce_price_with_symbol }}
              </div>
            </div>
          </template>
          <template #footer>
            <div class="virXrt_container">
              <div class="virXrt_line">
                <div class="virXrt-line_left">
                  <span class="virXrt-lineLeft_tips">{{ language.SHEIN_KEY_PC_28829 }}</span> 
                  <Icon
                    v-if="sceneLogic[productCode].iconDoubt"
                    name="sui_icon_doubt_16px_1"
                    size="16px"
                    style="cursor:pointer"
                    @click="clickDiscountTips($event, true)"
                  /> 
                </div>
                <div class="pickColor_xtra">
                  - {{ newProductPriceInfo._discountPrice }}
                </div>
              </div>
              <div class="virXrt_line">
                <div>{{ language.SHEIN_KEY_PC_28830 }}</div>
                <div class="pickColor_xtra">
                  - {{ newProductPriceInfo && newProductPriceInfo._conponPrice }}
                </div>
              </div>
            </div>
            <s-button
              :type="['primary']"
              width="100%"
              @click="virXrtCouponState.virtunalDiscountVisible=false"
            >
              {{ language.SHEIN_KEY_PC_28839 }}
            </s-button>
          </template>
        </s-dialog>

        <productAutoDrawer
          ref="productAutoDrawerRef"
          :language="language"
          :auto-type="autoType"
          @auto-submit="autoSubmit"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { template as _commonTemplate } from '@shein/common-function'

import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { scenceBuildStatus } from 'public/src/pages/checkout/utils.js'

import CouponWrap from './coupon.vue'
import XtraCouponList from './xtra_coupon_list'
import XtraRadioSelected from './xtra_radio_selected.vue'
import { Icon, sui_icon_reported_14px_1 } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  directives: { expose },
  components: {
    CouponWrap,
    XtraCoupon: defineAsyncComponent(() => import('./xtra_coupon')),
    Icon,
    sui_icon_reported_14px_1,
    XtraCouponList,
    XtraRadioSelected, 
    productAutoDrawer: defineAsyncComponent(() => import('public/src/pages/checkout/components/product_auto_drawer/index.vue')), 
    autoRenewedDialog: defineAsyncComponent(() => import('./components/autoRenewedDialog.vue')),
  },
  props: {
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      xtraSeleted: false,
      discountTipsVisible: false,
      autoRenewalDialogVisible: false,
      virXrtCouponState: {
        virtunalDiscountVisible: false,
      },
      isClient: false,
      isReportOnceEvent: false, // 埋点只上报一次
      bubbleDown: false,
      autoPaymentVisible: false,
      productAutoDrawerRef: null,
      nextPaymentInfo: null,
      xtraRadioSelectedRef: null,
      productCode: 'default',
      autoType: 'xtra',
    }
  },
  computed: {
    ...mapState([
      'status',
      'xtraProductInfo',
      'xtraDiscountInfo',
      'xtraCouponList',
      'xtraDisplayCouponInfo',
      'usedXtraCode',
      'coupon',
      'updatingPrice',
      'virtualCouponInfo',
      'language', 
      'checkout',
      'locals',
      'xtraAllProductBagInfo', 
      'selectedXtraProductCode', 
      'primeProductList',
    ]),
    ...mapGetters([ 'saverNewStyleAbt', 'xtraAutoController', 'productAutoInfo', 'saverHeightByAbt']),
    currentCardPay() {
      return this.status?.cardPay
    },
    currentCardPayRememberRadio(){
      return this.paymentMethodsProps?.paypalGaVault?.radioType != 1
    },
    currentAfterpayRememberRadio(){
      return this.paymentMethodsProps?.afterpayVault?.radioType != 1
    },
    xtraAllProductBagData(){
      return Object.values(this.xtraAllProductBagInfo) || []
    },
    // 是否在超省卡中展示自动续费产品包的UI逻辑
    isHasAutoRenewedBag() {
      return this.saverNewStyleAbt && this.xtraAutoController?.sheinSaverRenewAbt && this.xtraAllProductBagData?.some(i => i.productInfo?.product_activity_type == 1) 
    },
    // 在切换到自动续费产品包的时候，展示自动续费协议
    isShowAutoRenewedProtocol() {
      return this.isHasAutoRenewedBag && this.xtraAllProductBagInfo?.[this.usedXtraCode]?.productInfo?.product_activity_type == 1
    },
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    langPath() {
      return this.locals.langPath
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    SHEIN_SAVER_ARTICLE_IDS() {
      return this.locals.SHEIN_SAVER_ARTICLE_IDS
    },
    imgAll() {
      return {
        dialogHeaderBg: `${this.PUBLIC_CDN}/she_dist/images/checkout/xtra_header_bg-43453ce74b.png`,
      }
    },
    isGainReturns() {
      return parseFloat(this.xtraDiscountInfo?.total_discount?.amount) >= parseFloat(this.xtraProductInfo?.product_price_info?.special_price)
    },
    showDes(){
      return  !this.saverNewStyleAbt ? true : this.usedXtraCode ? true : false 
    },
    xtraLogoImg() {
      return this.GB_cssRight ? `${this.PUBLIC_CDN}/she_dist/images/checkout/sui_img_sheinsaver_ar-0057104b62.png` : `${this.PUBLIC_CDN}/she_dist/images/checkout/sui_img_sheinsaver-3a1433be13.png`
    },
    paymentMethods() {
      return this.checkout.results?.paymentMethods || []
    },
    nowPayment() {
      if(!this.status?.cardPay) return {}
      return this.paymentMethods?.filter(item => item.code == this.status.cardPay)?.[0] || {}
    },
    notSupport() {
      return this.nowPayment.support_xtra_with_order == 0
    },
    showDiscount() {
      const product_price_info = this.newProductPriceInfo
      return +product_price_info.price_local > +product_price_info.special_price || this.isProdctDuesCouponRights
    },
    isShowSpecialPriceHighColor() {
      if (this.SiteUID == 'fr' || ['pl', 'de'].includes(this.SiteUID) && this.hideSaverStrikethroughPrice) {
        return false
      }
      return this.showDiscount
    },
    isPreciseType() {
      return this.newProductPriceInfo.promotion_type == 5
    },
    showTipsArrowAbt() {
      return this.checkout.checkoutBFFAbtInfo?.PriceReminder?.param?.PriceReminderswitch == 'show'
    },
    discountTips() {
      let tips = this.language.SHEIN_KEY_PC_28826
      // 通过场景 是否需要展示带有'>'多语言key => 点击展开会费券弹窗
      if( this.sceneLogic[this.productCode].isVisible ||  this.sceneLogic[this.productCode].isLeg){
        tips = this.language.SHEIN_KEY_PC_28826 + ' > '
      }
      // 场景8,11,14，引导用会费券提示
      if( this.sceneLogic[this.productCode].isTips){
        typeof window != 'undefined' && daEventCenter.triggerNotice({
          daId: '1-11-1-177',
          extraData: {
            type: 'saver',
            position: 'page'
          }
        })
        return `${this.template(this.newProductPriceInfo.product_dues_coupon_rights.discount_value_with_symbol, this.language.SHEIN_KEY_PC_28827)} >`
      }
      return `${this.template(this.newProductPriceInfo.reduce_price_with_symbol, tips)}`
    },
    canShowDiscountTips() {
      return this.isPreciseType &&
        this.showTipsArrowAbt &&
        !this.notSupport &&
        this.status.hasAddress != 0
    },
    xtraPriceTips(){
      const languageMap = {
        'xtraDiscountTips': this.language.SHEIN_KEY_PC_30074,
        'caculateTips': this.language.SHEIN_KEY_PC_30075,
        'defaulTips': this.language.SHEIN_KEY_PC_30185
      }
      // 1. 使用的券是筛选出超省卡券的权益包卡券55
      // 2. 组装coupon_discount_info，根据筛选出的券，取对应discount_price值
      // 3. type: caculateTips取计价接口，xtraDiscountTips取试算接口，defaulTips兜底取超省卡查询接口
      // const usedXtraCoupon = (this.coupon.applyCouponsSuccess || [])
      //   .filter(f => [55].includes(f.typeId))
      //   .reduce((pre, next) => { return (pre?.price > next?.price ? pre : next) }, null)
      // const caculateCoupon = this.checkout?.mall_caculate_info?.coupon_discount_info ?? []
      // const coupon_discount_info = caculateCoupon.find(f => f.coupon_code == usedXtraCoupon?.couponCode) ?? {}
      // if(usedXtraCoupon && coupon_discount_info){
      //   Object.assign(coupon_discount_info, usedXtraCoupon)
      // }
      const usedXtraCoupon = !!this.coupon?.applyCouponsSuccess?.some(f => [55].includes(f.typeId))
      const caculateDiscount = this.checkout?.mall_caculate_info?.save_card_discount_price
      const type = this.usedXtraCode && usedXtraCoupon && caculateDiscount ? 'caculateTips' : 'xtraDiscountTips'
      const discount = type == 'caculateTips' ? caculateDiscount : this.xtraDiscountInfo?.total_discount
      const price = type == 'caculateTips' ? caculateDiscount?.amountWithSymbol : this.xtraDiscountInfo?.total_discount?.amountWithSymbol

      // 兜底默认取值
      if(!discount || !discount?.amount || +discount?.amount <= 0){
        const roi_order_count = this?.xtraProductInfo?.roi_order_count || 0
        return roi_order_count <= 0 ? '' : this.template(`<em>${roi_order_count}</em>`, languageMap.defaulTips)
      }
      return this.template(`<em>${price || 0}</em>`, languageMap[type])
    },
    roiTips() {
      if(this.saverNewStyleAbt){
        const languageMap = {
          'xtraDiscountTips': this.language.SHEIN_KEY_PC_30076,
          'caculateTips': this.language.SHEIN_KEY_PC_30077,
        }
        const xtraCouponListLen = this.xtraDisplayCouponInfo?.count || 0
        // if(!this.usedXtraCode){
        //   return xtraCouponListLen ? this.template(`${xtraCouponListLen}`, languageMap.xtraDiscountTips) : ''
        // }
        return xtraCouponListLen ? `
            ${this.template(`${xtraCouponListLen}`, `${languageMap.xtraDiscountTips}`)}
            ${this.template( this.xtraProductInfo.product_cycle_days, `| ${languageMap.caculateTips}`)}
          ` : ''
      }

      if (this.isGainReturns) {
        return this.template(
          this.xtraProductInfo.product_cycle_days,
          this.language.SHEIN_KEY_PC_28065
        )
      }
      return this.template(
        `<em>${this.xtraProductInfo.roi_order_count}</em>`, 
        this.xtraProductInfo.product_cycle_days,
        this.language.SHEIN_KEY_PC_28066
      )
    },
    policyTips() {
      const url = `${this.langPath}/shein-saver-terms-and-conditions-a-${this.SHEIN_SAVER_ARTICLE_IDS}.html`
      return this.template(url, this.language.SHEIN_KEY_PC_27085)
    },
    xtraOptionAnalysis() {
      if(typeof window == 'undefined') return {}

      return { original_price: this.newProductPriceInfo?.price_local_with_symbol || '-', special_price: this.newProductPriceInfo?.special_price_with_symbol || '-', select_flag: this.xtraSeleted ? 1 : 0 }
    },
    productPriceInfo(){
      return this.xtraProductInfo?.product_price_info || {}
    },
    productDuesCouponRightsInfo(){
      return this.productPriceInfo?.product_dues_coupon_rights || {}
    },
    isProdctDuesCouponRights(){
      return this.productDuesCouponRightsInfo?.coupon_code
    },
    applyCouponsVir(){
      return this.currentProductInfo._xtraStatusInfo.applyCouponsVir
    },
    sceneLogic(){
      // 超省卡自动续费改动
      return scenceBuildStatus(this.xtraAutoController.xtraAllproduct, this.showTipsArrowAbt)
    },
    currentProductInfo(){
      return this.productCode == 'default' ? this.xtraAutoController?.xtraProductNormal[0] :  this.sceneLogic[this.productCode].productInfo || {}
    },
    newProductPriceInfo(){
      return this.currentProductInfo?.product_price_info || {}
    },
    hideSaverStrikethroughPrice() {
      return this.checkout.HIDE_SAVER_STRIKETHROUGH_PRICE === 'on'
    }
  },
  watch: {
    usedXtraCode: {
      handler(n) {
        this.xtraSeleted = !!n
        this.autoPaymentVisibleFn()
      },
      immediate: true
    },
    currentCardPayRememberRadio: {
      handler(){
        this.autoPaymentVisibleFn()
      }
    },
    currentAfterpayRememberRadio: {
      handler(){
        this.autoPaymentVisibleFn()
      }
    },
    currentCardPay: {
      handler(){
        this.autoPaymentVisibleFn()
      },
      immediate: true
    },
    isShowAutoRenewedProtocol: {
      handler(val) {
        if (val && !this.isReportOnceEvent) {
          this.isReportOnceEvent = true
          daEventCenter.triggerNotice({
            daId: '1-11-1-225'
          })
        }
      },
      immediate: true,
    }
  },
  mounted(){
    this.isClient = true
    this.upDateSceneStatusNew()
    this.handleEventsListen()
  },
  methods: {
    ...mapMutations(['assignState', 'upDateSceneStatusNew', 'changeParamsStatus', 'updateCheckoutState', 'changeVirtualCouponInfo', 'updateSelectedPrime']),
    template: _commonTemplate,
    handleEventsListen() {
      window.checkoutEventCenter?.on('xtra-coupon-dialog-change', ({ e, productCode }) => {
        this.productCode = productCode || this.usedXtraCode || this.xtraAutoController.xtraProductNormal[0].product_code || 'default'
        if(this.sceneLogic[this.productCode].isLeg){
          this.clickDiscountTips(e, true, {
            type: 'saver',
            position: 'popup'
          })
        } else {
          this.clickDiscountTips(e, false, {
            type: 'saver',
            position: 'popup'
          })
        }
      })

      window?.checkoutEventCenter.on('payment-choosepayment-event', (opt)=>{
        if(['xtra', 'common'].includes(opt.currentAutoProductDwType) ) {
          this.autoType = opt.currentAutoProductDwType
          this.bubbleEvent(opt)
          this.nextPaymentInfo = opt
        }
      })
      // 监听 autoPaymentGuide组件，触发切包事件。
      window.checkoutEventCenter && window.checkoutEventCenter.on('xtra-auto-payment-method-submit', ({ productCode, DwVisible } = { productCode: '', DwVisible: false }) => {
        this.autoSubmitPayment({ productCode, DwVisible })
      })
    },
    clickXtra({ type = '' }) {
      if ((type == 'all' && this.isHasAutoRenewedBag) || this.notSupport) {
        return
      }
      if (this.notSupport || this.status.hasAddress == 0) return
      
      if (this.isHasAutoRenewedBag) {
        this.xtraSeleted = !!this.usedXtraCode
      } else {
        this.xtraSeleted = !this.xtraSeleted
      }

      this.changeVirtualCouponInfo({
        currentClickType: 'xtra'
      })
      this.reverseXtra()
      daEventCenter.triggerNotice({
        daId: '1-11-1-158', 
        extraData: {
          ...this.xtraOptionAnalysis
        }
      })
    },
    chooseXtra(flag) {
      this.xtraSeleted = flag
      this.reverseXtra()
    },
    reverseXtra() {
      this.assignState({ 
        'usedXtraCode': this.xtraSeleted ? this.xtraProductInfo.product_code : ''
      })

      window.checkoutEventCenter.emit('change-xtra-calcu', {
        xtraSeleted: this.xtraSeleted,
      })
    },
    async clickDetails(ignoreDisabled) {
      const disabled = this.notSupport || this.status.hasAddress == 0
      if (!ignoreDisabled && disabled) return

      daEventCenter.triggerNotice({
        daId: '1-11-1-159',
        extraData: {
          original_price: this.xtraOptionAnalysis.original_price,
          special_price: this.xtraOptionAnalysis.special_price
        }
      })
      this.assignState({
        'showXtraCoupon': true
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-160',
        extraData: {
          original_price: this.xtraOptionAnalysis.original_price,
          special_price: this.xtraOptionAnalysis.special_price
        }
      })
    },
    clickPolicy(e) {
      if (this.notSupport || this.status.hasAddress == 0) {
        e.preventDefault()
      }
      if(e.target?.tagName == 'A') {
        daEventCenter.triggerNotice({
          daId: '1-11-1-162',
          extraData: {
            scene: 'entrance'
          }
        })
      }
    },
    xtraRadioSelectClickDisountCb(e, productCode){
      this.productCode = productCode
      this.clickDiscountTips(e)
    },
    clickDiscountTips(e, visible, extraDataEventOpt) {
      if(this.sceneLogic[this.productCode].isTips){
        daEventCenter.triggerNotice({
          daId: '1-11-1-178',
          extraData: {
            type: 'saver',
            position: 'page',
            ...extraDataEventOpt
          }
        })
        // 前往券列表
        e.stopPropagation()
        return this.goCouponPage()
      }
      if (!this.canShowDiscountTips && ! this.sceneLogic[this.productCode].isVisible) return
      e.stopPropagation()
      if( this.sceneLogic[this.productCode].isVisible && !visible){
        // 优惠弹窗
        this.virXrtCouponState.virtunalDiscountVisible = true
      } else {
        // 法务弹窗
        this.discountTipsVisible = true
      }
    },

    goCouponPage () {
      // 只有第一次展示红点
      this.changeParamsStatus({
        type: 'coupon',
        params: {
          showCouponList: true
        }
      })
      this.updateCheckoutState({
        key: 'hasEnterCouponList',
        val: true
      })
    },

    autoPaymentVisibleFn(){
      // 针对paypal-gapaypal/afterpay-card 做额外处理，没有勾选remenber，则展示引导切快捷
      const { xtraCurrentAutoPayLimit } = this.xtraAutoController
      const cardPay = this.status?.cardPay?.toLowerCase()
      const currentCardPay =  ['paypal-gapaypal', 'afterpay-card'].includes(cardPay)
      if(currentCardPay){
        this.autoPaymentVisible = cardPay == 'paypal-gapaypal' ? !xtraCurrentAutoPayLimit || this.currentCardPayRememberRadio : !xtraCurrentAutoPayLimit || this.currentAfterpayRememberRadio
      } else {
        this.autoPaymentVisible = !xtraCurrentAutoPayLimit
      }
    },

    // 唤起切包逻辑
    bubbleEvent(opt){
      const { title, code, logo_url } = opt?.item || { title: '', code: '', logo_url: '' }
      const { currentProduct, currentProductCycleDays } = this.xtraAutoController || {}
      const paymentInfo = { title, code, logo: logo_url }
      this.$refs.productAutoDrawerRef.visibleEvent( { visible: true, currentProduct, currentProductCycleDays, paymentInfo, normalShow: true })
    },
    // 切包回调
    autoSubmit({ productCode, isdont } = { productCode: '', isdont: false }){
      const switchProductCode = productCode

      if(switchProductCode) { 
        window.checkoutEventCenter.emit('again-choose-payment', { paymentInfo: this.nextPaymentInfo, isdont, afterCallBack: ({ isdont }) => {
        // 封装的选中按钮组件
          let options = null
          if(isdont){
            // 取消包勾选
            this.assignState({ autoCulateApi: true, usedXtraCode: '' })
            if(this.autoType == 'common'){
              this.assignState({ usedPrimeIndex: -1, usedPrimeCode: '' })
              options = { xtraSeleted: false, primeSeleted: false }
            }
          } else {
            if(this.autoType == 'common'){
              options = { xtraSeleted: true, primeSeleted: true }
            }
          }
          this.changeXtraProductInfo({ currentCode: switchProductCode, options })
        } })
      }
    },
    // 支付方式切包回调(内页和外页)
    autoSubmitPayment({ productCode, DwVisible }){
      const switchProductCode = productCode
      this.assignState({ 
        'usedXtraCode': this.xtraSeleted ? this.xtraProductInfo.product_code : ''
      })
      this.changeXtraProductInfo({ currentCode: switchProductCode, DwVisible, type: 'direct' })
      // 封装的选中按钮组件
    },

    // 切包更新值
    changeXtraProductInfo({ currentCode, DwVisible, type, options } = { currentCode: '', DwVisible: false, type: 'init', options: null }){
      const selectProductInfo = this.xtraAllProductBagData?.find(i => i?.productInfo?.product_code == currentCode) || {}
      this.assignState({
        xtraCouponList: selectProductInfo?.couponListInfo?.couponList || [],
        xtraAggregatedCouponList: selectProductInfo?.couponListInfo?.aggregatedCouponList || [],
        xtraProductInfo: selectProductInfo?.productInfo || {},
        xtraDiscountInfo: selectProductInfo?.discountInfo || {},
        xtraDisplayCouponInfo: selectProductInfo?.displayCouponInfo || {},
      })
      // 两个包需要同时更新
      if(options){
        if(options?.xtraSeleted && options?.primeSeleted){
          this.assignState({ 'usedXtraCode': this.xtraProductInfo.product_code })
          const { currentProductCycleDays } = this.productAutoInfo
          const selectdPrimeIndex = this.primeProductList?.findIndex( item => currentProductCycleDays?.product_code == item.product_code)
          this.assignState({
            usedPrimeIndex: selectdPrimeIndex,
            usedPrimeCode: this.primeProductList?.[selectdPrimeIndex]?.product_code
          })
          this.updateSelectedPrime({ index: selectdPrimeIndex == -1 ? 0 : selectdPrimeIndex, isUpdateRightList: true })
        }
        return this.changeXtraPrimeUpDateCalcu(options)
      }
      if(DwVisible){
        // 内页回调
        this.chooseXtra(true)
        this.assignState({
          'showXtraCoupon': false
        })
      } else {
        // 非init 直接调选择
        if(type == 'direct'){
          this.chooseXtra(true)
        } else {
          this.clickXtra({})
        }
      }
    },
    changeXtraPrimeUpDateCalcu(options){
      window.checkoutEventCenter.emit('change-xtra-prime-calcu', options)
    }
  }
}
</script>

<style lang="less">
.pickColor_xtra{
  color: #F82854
}
.mrg-4{
    margin-left: -3px;
  }
.checkout-xtra__wrap {
  margin-top: 8px;

  .checkout-xtra__wrap-logo {
    width: 100%;
    height: 26px;
  }

  .checkout-xtra__wrap-body {
    display: flex;
    align-content: space-between;
    align-items: flex-start;
    padding: 8px 12px;
    background: #FFF;
    cursor: pointer;

    .sui-radio {
      .margin-r(0);
    }
  }

  .checkout-xtra__wrap-content {
    width: 100%;
    min-width: 0;
  }

  .checkout-xtra__wrap-name {
    .checkout-xtra__wrap-price-special {
      color: @sui_color_gray_dark1;
      font-size: 16px;
      font-weight: 700;
      .margin-r(4px);

    }

    .checkout-xtra__price_ar {
      font-family: SF Pro;
    }

    .discount-price {
      color: #F82854;
    }
    .checkout-xtra__wrap-price-del {
      color: @sui_color_gray_light1;
      font-size: 14px;
      font-weight: 400;
      .margin-r(4px);
    }
    .checkout-xtra__wrap-price-local {
      color: #222;
      font-size: 12px;
      font-size: 12px;
      font-weight: 400;
      .margin-r(4px);
    }
    .checkout-xtra__wrap-price-discount {
      display: inline-block;
      border-radius: 6px 0px;
      background: linear-gradient(270deg, #F82854 0%, #DE00F1 100%);
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      padding: 2px 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      vertical-align: middle;
    }
    .checkoutXtra__activeXtra-dicount_bg{
      background: #FEE8ED;
      border: 1px solid #F74D81;
      color: #EB3662;
    }
  }

  .checkout-xtra__wrap-des {
    margin-top: 8px;
    font-size: 14px;
    em {
      color: #F82854;
    }
  }
  .checkout-xtra__NewWrapDes{
    color: var(---sui_color_gray_dark3, #767676);
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // margin-top: 1px;
    em {
      color: #F82854;
    }
  }
  .checkout-xtra__NewWrapDesTitle{
      font-family: "SF Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    em {
      color: #F82854;
      font-weight: 860;
    }
  }

  .xtra-hearder{
    font-family: "SF Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
    em {
      color: #F82854;
      font-weight: 860;
    }
  }

  .checkout-xtra__wrap-list {
    margin-top: 3px;
  }

  .checkout-xtra__wrap-coupon {
    margin-top: 8px;
  }
  .container-radio{
    display: flex;
    margin-top: 9px;
    align-items: center;
  }

  .checkout-xtra__wrap-policy {
    margin-top: 8px;
    font-size: 10px;
    color: @sui_color_gray_light1;

    .polict-detail {
      color: @sui_color_link;
      cursor: pointer;
    }
    .auto-renewed-protocol {
      cursor: pointer;
      color: var(---sui_color_link, #2D68A8);
    }
  }

  .checkout-xtra__wrap-disabled {
    font-size: 12px;
    color: @sui_color_unusual;
    i {
      .margin-r(4px);
    }
    margin-top: 8px;
  }

  .disabled {
    opacity: 0.3;
  }

  .header-bg{
    position: absolute;
    top: 0;
    left: 0px;
  }
  .header-bg-container{
    position: relative;
    margin-top: -20px;
    font-size: 16px;
    .xtra-text{
      font-size: 24px;
      font-weight: 600;
      line-height: 1.7;
    }
  }

  .virXrt_container{
    margin-bottom: 36px;
    font-size: 14px;
    .virXrt_line{
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .virXrt-line_left{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    .virXrt-lineLeft_tips{
      margin-right: 5px;
    }
  }
}
</style>
